




























import BaseCommentWidget from '../../../../base/components/sidebar/widgets/CommentWidget.vue'

export default class CommentWidget extends BaseCommentWidget {}
