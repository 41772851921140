



















import { Vue, Prop, Component } from 'vue-property-decorator'

import { undomainify } from '~plugins/utils'

interface Comment {
  author: {
    avatar?: {
      url: string
    }
    name: string
    altPicture?: string
  }
  dateGmt: string
  title: string
  isComment: boolean
  excerpt: string
  link: string
}
interface CommentDataResult {
  search: {
    nodes: Comment[]
  }
}

@Component({})
export default class CommentWidget extends Vue {
  @Prop({ type: Object, default: null })
  readonly initialData!: CommentDataResult

  @Prop({ type: String, default: '' }) readonly title!: string
  @Prop({ type: String, default: 'comment-author' }) readonly template!: string

  get theComment(): Comment | undefined {
    if (!this.initialData) return undefined
    return this.initialData.search.nodes[0]
  }

  get linksTo(): string {
    return undomainify(this.theComment?.link || '')
  }

  get authorPicture(): string {
    const author = this.theComment?.author
    return author?.altPicture || author?.avatar?.url || ''
  }

  get isAvatarPicture(): boolean {
    return this.theComment?.author.altPicture === null
  }

  navigate() {
    this.$router.push(this.linksTo)
  }
}
